import { Component, Prop, Vue } from 'vue-property-decorator'

// import { api } from '@/services/api'

@Component({
	
})
export default class ListaLeisGoogleSearchResult extends Vue {
  @Prop() list!: Array<object>;
  
  exibir = 'is-12';
  mark = true;
  
  changeExibir(){
    this.mark = !this.mark;
    console.log(this.mark);
    if(this.mark){
      this.exibir = 'is-12';
    }
    else{
      this.exibir = 'is-6';
    }
  }

  ajustLeiText(text: string){
    return text;
    let splittedArray = text.split('...')
    splittedArray = splittedArray.filter(element => {
      return element.length > 20
    });
    let finalText = ''
    finalText = splittedArray.join('...')
    return `... ${finalText} ...`
  }

  ajustLeiTitle(htmlFormattedUrl: string){
    const splittedArray: string[] = htmlFormattedUrl.split('/')
    const noFormat = splittedArray[splittedArray.length-1]
    const ano = noFormat.slice(noFormat.length-4, noFormat.length)
    const numero = noFormat.slice(1, noFormat.length-4)
    let type = noFormat[0]
    if(type == 'P'){
      type = 'Portaria'
    }
    else if(type == 'E'){
      type = 'Emenda'
    }
    else if(type == 'O'){
      type = 'Lei Orgânica'
    }
    else if(type == 'C'){
      type = 'Lei Complementar'
    }
    else if(type == 'D'){
      type = 'Decreto'
    }
    else if(type == 'R'){
      type = 'Resolução'
    }
    else {
      type = 'Lei'
    }
    return `${type} nº ${numero}/${ano}`
}
} 
