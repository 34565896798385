import { Component, Vue } from 'vue-property-decorator'

import PublicNavbarLeis from '@/components/publicNavbarLeis/PublicNavbarLeis.vue'
import ListaLeisGoogleSearchResult from '@/components/listaLeisGoogleSearchResult/ListaLeisGoogleSearchResult.vue'

import { api } from '@/services/api'

/*
https://www.googleapis.com/customsearch/v1/siterestrict?key=AIzaSyAdYnHQN7WTQR7gTn4T83BsLKxfHnfJ6L4&cx=01951431088d64f49&start=0&sort=date&q=pagamento
https://www.googleapis.com/customsearch/v1/siterestrict?key=AIzaSyAdYnHQN7WTQR7gTn4T83BsLKxfHnfJ6L4cx=01951431088d64f49&start=0&sort=date&q=pagamento
*/

@Component({

	components:{
		PublicNavbarLeis,
    ListaLeisGoogleSearchResult
	}

})

export default class BuscaPalavraChave extends Vue {

  leis = [];
  temas = [];
  countLeis = 0
  loading = false;
  dataResponse: any = {
    queries: {}
  }
  filtro: any = {
    q: ''
  };
  pagination: Record<string, string | number> = {
    key: 'AIzaSyAdYnHQN7WTQR7gTn4T83BsLKxfHnfJ6L4',
    cx: '01951431088d64f49',
    start: 0,
    sort: 'date',
    hl: 'pt-BR'
  };
  bpagination = 1;

  mounted() {
    // this.sendGet(this.mountUrlQueryPagination());
    // this.sendGetAllTemas();
  }

  changePagination(param: any){
    console.log(param);
    console.log('result:', (param*10)-10);
    this.pagination.start = (param*10)-10;
    this.clickBtnProcurar();
  }

  clickBtnProcurar(){
    const query = this.mountUrlQuery();
    console.log(query);
    this.sendGet(query);
  }

  clickBtnNext(){
    this.pagination.start = this.dataResponse.queries.nextPage[0].startIndex
    const query = this.mountUrlQuery();
    console.log(this.dataResponse.queries.nextPage);
    this.sendGet(query);
    this.goToTop();
  }
  
  clickBtnPrevious(){
    this.pagination.start = this.dataResponse.queries.previousPage[0].startIndex
    const query = this.mountUrlQuery();
    console.log(this.dataResponse.queries.previousPage);
    this.sendGet(query);
    this.goToTop();
  }

  goToTop(){
    window.scrollTo(0,0);
  }

  mountUrlQuery(){
    let query = this.mountUrlQueryPagination();
    query = query + '&' + this.mountUrlQueryFiltro();
    return query;
  }

  mountUrlQueryPagination(){
    let str  = '';
    let i = 1;
    for(const key in this.pagination){
      str = `${str}${key}=${this.pagination[key]}`;
      if( i >= 1 && i < Object.keys(this.pagination).length){
        str = str + '&'
      }
      i = i + 1;
    }
    return str;
  }

  mountUrlQueryFiltro(){
    let str  = '';
    let i = 1;
    for(const key in this.filtro){
      if( this.filtro[key] != '' ){
        if(key == 'ementa'){
          str = str + 'ementa_like' + '=' + this.filtro[key];
        }
        else{
          str = str + key + '=' + this.filtro[key];
        }
        // adiciona caracter & na query
        if( i >= 1 && i < Object.keys(this.filtro).length){
          str = str + '&'
        }
      }
      i = i + 1;
    }
    return str;
  }

  sendGet(query: string){
    this.loading = true;
    api.get('https://www.googleapis.com/customsearch/v1/siterestrict?'+query)
      .then( (response) => {
        this.countLeis = parseInt(response.data.searchInformation.totalResults);
        this.dataResponse = response.data
        this.leis = response.data.items;
        this.loading = false;
        console.log(response);
      })
      .catch( (error) => {
        this.loading = false;
        console.log(error);
      })
  }

  sendGetAllTemas(){
    // this.loading = true;
    api.get('/temas')
      .then( (response) => {
        // this.countLeis = response.headers["x-total-count"];
        this.temas = response.data;
        // this.loading = false;
        console.log(response);
      })
      .catch( (error) => {
        // this.loading = false;
        console.log(error);
      })
  }

}
